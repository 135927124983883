@use 'styles.variables' as vars;
@use '@angular/material' as mat;

@include mat.all-component-typographies();
// Repeated classes for higher specificity to prevent angular-material-css-vars from overriding.
body {
  @include mat.checkbox-density(-3);

  // Tailwind bug adding border to label on focus of outline matInputs: https://github.com/angular/components/issues/26102, https://github.com/tailwindlabs/tailwindcss/discussions/9993
  .mdc-text-field--focused .mdc-notched-outline__notch {
    border-right-style: hidden;
  }

  .high-contrast-theme.high-contrast-theme.high-contrast-theme {
    color: rgba(var(--palette-primary-contrast-500)) !important;
    $high-contrast-palette: mat.define-palette(mat.$grey-palette, 50);
    $high-contrast-theme: mat.define-dark-theme(
      (
        color: (
          primary: $high-contrast-palette,
          accent: $high-contrast-palette,
        ),
      )
    );
    @include mat.all-component-colors($high-contrast-theme);

    // Override high-contrast fields with inherited colors.
    // TODO: This needs to be handled by the DLS theming.
    .mat-select-placeholder,
    .mdc-floating-label,
    .mat-select-value,
    .mat-mdc-select-value,
    .mdc-text-field__input,
    .mat-select-arrow,
    .mat-mdc-select-arrow,
    .mdc-form-field,
    .mat-mdc-button,
    .platform-button,
    a {
      color: inherit !important;
    }
    .mat-form-field-label {
      color: inherit !important;
      opacity: 0.7;
    }
    .mat-form-field-underline::before,
    .mat-form-field-ripple {
      background-color: currentColor !important;
    }
    .mdc-text-field--filled {
      background-color: inherit !important;
    }
    .mat-select.mat-select-disabled {
      .mat-select-placeholder,
      .mat-select-value,
      .mat-mdc-select-min-line,
      .mat-select-arrow,
      .mat-mdc-select-arrow {
        opacity: 0.7;
      }
    }
    .mat-checkbox-frame, .mdc-checkbox__background {
      border-color: currentColor !important;
    }
    .zelis-dls button.zelis-button.dark-bg.mat-stroked-button.mat-primary {
      color: inherit !important;
      border-color: currentColor !important;
    }
  }
}

// Primary Color
.primary-color-background {
  fill: rgb(var(--palette-primary-500));
  background-color: rgb(var(--palette-primary-500));
}

.primary-color-background-text {
  color: rgba(var(--palette-primary-contrast-500));
}

.primary-color-text {
  color: rgb(var(--palette-primary-500));
}

.primary-color {
  @extend .primary-color-background;
  @extend .primary-color-background-text;
}

// Accent Color
.accent-color-background {
  fill: rgb(var(--palette-accent-500));
  background-color: rgb(var(--palette-accent-500));
}

.accent-color-background-text {
  color: rgba(var(--palette-accent-contrast-500));
}

.accent-color-text {
  color: rgb(var(--palette-accent-500));
}

.accent-color {
  @extend .accent-color-background;
  @extend .accent-color-background-text;
}

// Warn Color
.warn-color-background {
  fill: rgb(var(--palette-m3-system-red-40));
  background-color: rgb(var(--palette-m3-system-red-40));
}

.warn-color-background-text {
  color: rgba(var(--palette-m3-system-red-contrast-40));
}

.warn-color-text {
  color: rgb(var(--palette-m3-system-red-40));
}

.warn-color {
  @extend .warn-color-background;
  @extend .warn-color-background-text;
}

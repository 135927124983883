@import 'styles.variables';
@import 'styles.mixins';

.platform-button {
  font-size: $app-button-font-size;
  line-height: $app-button-line-height;

  &.mat-stroked-button.mat-accent {
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: rgb(var(--palette-accent-900));
    }
  }

  &.mat-flat-button,
  &.mat-stroked-button,
  &.mat-raised-button {
    @include padding($top: 0, $right: 6, $bottom: 0, $left: 6);

    & > .mat-button-wrapper > {
      .button-icon,
      [class^='icon-'],
      mat-icon,
      .fa,
      svg,
      img {
        display: inline-block;
        vertical-align: middle;
        @include margin($right: 1, $left: -1);
      }
    }

    &.mat-stroked-button {
      border: $app-stroked-button-border-color solid 1px;
      background-color: $app-white;

      &.button-active,
      &:active,
      &:hover,
      &:focus {
        background-color: $app-stroked-button-active-color;
      }

      &:focus:not(:active) {
        text-decoration: underline;
      }
    }

    &.button-link {
      padding: 0 0;
      background-color: transparent;
      border: 0;

      .mat-button-focus-overlay,
      .mat-button-ripple {
        display: none;
      }

      &:hover,
      &:focus,
      &:active,
      &.button-active {
        background-color: transparent;

        &:not(i):not(mat-icon):not(.fa):not(svg):not(img) {
          text-decoration: underline;
        }
      }
    }
  }

  &.mat-icon-button {
    padding: 0 0;
    background-color: transparent;
    height: $app-button-line-height;
    width: $app-button-line-height;

    &.button-border {
      border: transparent solid 2px;
    }

    & > .mat-button-wrapper > {
      .button-icon,
      [class^='icon-'],
      mat-icon,
      .fa,
      svg,
      img {
        margin: 0 0;
      }
    }

    &.button-light {
      &:not([color]) {
        color: rgba(255, 255, 255, 0.7);

        &.button-border {
          border-color: rgba(255, 255, 255, 0.7);
        }
      }

      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 1);

        &.button-border {
          border-color: rgba(255, 255, 255, 1);
        }
        background-color: rgba(255, 255, 255, 0.05);
      }

      &:focus,
      &:active,
      &.button-active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &.button-dark {
      &:not([color]) {
        color: rgba($app-black, 0.6);

        &.button-border {
          border-color: rgba($app-black, 0.6);
        }
      }

      &:hover,
      &:focus {
        color: rgba($app-black, 1);
        border-color: rgba($app-black, 1);
        background-color: rgba($app-black, 0.05);
      }

      &:focus,
      &:active,
      &.button-active {
        background-color: rgba($app-black, 0.1);
      }
    }
  }

  &.mat-fab {
    & > .mat-button-wrapper > {
      .button-icon,
      [class^='icon-'],
      mat-icon,
      .fa,
      svg,
      img {
        display: block;
      }
    }

    &.button-fab-peeker {
      width: auto;

      &,
      .mat-button-ripple-round {
        border-radius: 28px;
      }

      .mat-button-wrapper {
        padding: 0 16px;

        & > * {
          display: inline-block;
        }

        .button-text {
          width: auto;
          font-weight: $app-font-weight-regular;
          overflow: hidden;
        }
      }
    }
  }

  &.mat-flat-button,
  &.mat-stroked-button,
  &.mat-raised-button {
    &.button-small {
      font-size: $app-button-small-font-size;
      line-height: $app-button-small-line-height;
      font-weight: $app-font-weight-regular;
      @include padding($right: 4, $left: 4);

      & > .mat-button-wrapper > {
        .button-icon,
        [class^='icon-'],
        mat-icon,
        .fa,
        svg,
        img {
          @include icon-size($size: 'small');
        }
      }
    }
  }

  &.mat-icon-button,
  &.mat-fab {
    &.button-small {
      height: $app-button-small-line-height;
      width: $app-button-small-line-height;
      font-size: $app-button-font-size;
      line-height: $app-button-font-size;

      .mat-button-wrapper {
        padding: 0 0;
      }

      & > .mat-button-wrapper > {
        .button-icon,
        [class^='icon-'],
        mat-icon,
        .fa,
        svg,
        img {
          display: block;
          @include icon-size($size: 'small');
          height: inherit;
          width: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }

  &.button-icon-right {
    & > .mat-button-wrapper > {
      .button-icon,
      [class^='icon-'],
      mat-icon,
      .fa,
      svg,
      img {
        @include margin($right: -1, $left: 1);
      }
    }
  }

  &.button-narrow {
    @include padding($x: 2, $important: true);
  }

  &.button-basic {
    background-color: $app-white;
    color: $app-color-accent;

    &[disabled] {
      background-color: $app-white;
    }
  }

  &.button-clear {
    background-color: transparent;
    border: 0;
  }

  &.button-translucent {
    opacity: 0.95;

    &:active,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &.button-sharp {
    border-radius: 0;
  }

  &.button-filter {
    &:not(.button-large) {
      @extend .button-small;
    }

    &.text-medium {
      .mat-button-wrapper {
        font-weight: $app-font-weight-medium;
      }
    }

    @extend .button-narrow;
    @extend .button-clear;
    border: rgba($app-filter-button-border-color-light, 0.7) solid 1px;
    color: rgba($app-filter-button-border-color-light, 1);
    transition: border-color 300ms, background-color 300ms;

    &.button-active,
    &:active,
    &:hover,
    &:focus {
      border-color: rgba($app-filter-button-border-color-light, 1);
    }

    &.button-active,
    &:active {
      background-color: rgba($app-filter-button-active-color-light, 0.2);
      border-color: rgba($app-filter-button-active-color-light, 0);
    }

    &.button-dark {
      border: rgba($app-filter-button-border-color-dark, 0.5) solid 1px;
      color: rgba($app-filter-button-border-color-dark, 1);

      &.button-active,
      &:active,
      &:hover,
      &:focus {
        border-color: rgba($app-filter-button-border-color-dark, 1);
      }

      &.button-active,
      &:active {
        background-color: rgba($app-filter-button-active-color-dark, 0.2);
        border-color: rgba($app-filter-button-active-color-dark, 0);
      }
    }

    &.button-accent {
      border: rgba($app-color-accent, 0.5) solid 1px;
      color: rgba($app-color-accent, 1);

      &.button-active,
      &:active,
      &:hover,
      &:focus {
        border-color: rgba($app-color-accent, 1);
      }

      &.button-active,
      &:active {
        background-color: rgba($app-filter-button-active-color-dark, 0.2);
        border-color: rgba($app-filter-button-active-color-dark, 0);
      }
    }
  }

  &.button-hero {
    background-color: $app-button-hero-color;
    color: $app-button-hero-text-color;
    font-weight: $app-font-weight-medium;
    line-height: $app-button-hero-line-height;
    @include padding($right: 4, $left: 4);

    & > .mat-button-wrapper > {
      .button-icon,
      [class^='icon-'],
      mat-icon,
      .fa,
      svg,
      img {
        @include icon-size($size: 'medium');
      }
    }
  }

  &.button-full {
    width: 100%;
    @include padding($right: 1, $left: 1);
  }
}

.mobile {
  .platform-button {
    &.button-mobile-icon {
      @include padding($right: 3, $left: 3);
      min-width: 0;

      .mat-button-wrapper {
        font-size: 0;
      }

      & > .mat-button-wrapper > {
        .button-icon,
        [class^='icon-'],
        mat-icon,
        .fa,
        svg,
        img {
          @include margin($right: 0, $left: 0);
        }
      }
    }

    &.button-mobile-text {
      @include padding($right: 3, $left: 3);

      & > .mat-button-wrapper > {
        .button-icon,
        [class^='icon-'],
        mat-icon,
        .fa,
        svg,
        img {
          display: none;
        }
      }
    }
  }
}

.platform-button-group {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  border-radius: $app-button-radius;

  .platform-button {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    justify-content: center;
    margin: 0 0;
    min-width: 0 !important;
    padding: 0 16px !important;
    box-shadow: unset;

    &.button-small {
      padding: 0 8px !important;
    }

    &.button-icon-only {
      & > .mat-button-wrapper > {
        .button-icon,
        [class^='icon-'],
        mat-icon,
        .fa,
        svg,
        img {
          margin: 0 0;
        }
      }
    }

    &:first-child {
      border-top-left-radius: $app-button-radius !important;
      border-bottom-left-radius: $app-button-radius !important;
    }

    &:last-child {
      border-top-right-radius: $app-button-radius !important;
      border-bottom-right-radius: $app-button-radius !important;
    }
  }

  &.button-group-dividers {
    .platform-button:not(:first-child) {
      border-left: 1px rgba($app-white, 0.2) solid;

      &.button-basic {
        border-left: 1px $app-stroked-button-border-color solid;
      }
    }
  }

  &.button-group-vertical {
    flex-direction: column;

    .platform-button {
      border-radius: 0 !important;

      &:first-child {
        border-top-left-radius: $app-button-radius !important;
        border-top-right-radius: $app-button-radius !important;
      }

      &:last-child {
        border-bottom-left-radius: $app-button-radius !important;
        border-bottom-right-radius: $app-button-radius !important;
      }
    }

    &.button-group-dividers {
      .platform-button:not(:first-child),
      .zelis-dls:not(:first-child) {
        border-left: 0;
        border-top: 1px rgba($app-white, 0.2) solid;

        &.button-basic {
          border-left: 0;
          border-top: 1px $app-stroked-button-border-color solid;
        }
      }
    }
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

// Temporary animation fix related to
// https://github.com/angular/angular/issues/30314
.ng-trigger-slideInOutAnimation {
  &.ng-animating {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.animate-disabled {
  transition: none !important;
}

@import 'styles.pui-comps.variables';

/**
 * App Style Variables
 */
$disclaimer-color: #fff9c4 !default;
$header-text-color: rgb(var(--palette-m3-system-gray-35)) !default;
$link-blue: rgb(var(--palette-m3-system-blue-40)) !default;
$incentives-green: rgb(var(--palette-m3-system-green-40)) !default;
$incentives-green-background: rgb(var(--palette-m3-system-green-30)) !default;
$incentives-light-green-background: rgb(
  var(--palette-m3-system-green-95)
) !default;
$green-text: rgb(var(--palette-m3-system-green-40)) !default;
$background-dark-green: rgb(var(--palette-m3-system-green-40)) !default;
$app-white: white;
$app-black: black;
$background-gray: rgb(var(--palette-m3-system-gray-95)) !default;
$divider-gray: rgb(var(--palette-m3-system-gray-85)) !default;
$gray-on-gray: rgb(var(--palette-m3-system-gray-35)) !default;
$gray-on-white: rgb(var(--palette-m3-system-gray-35)) !default;
$filters-gray: rgb(var(--palette-m3-system-gray-35)) !default;
$mat-form-border: rgb(var(--palette-m3-system-gray-60)) !default;
$chrome-focus: -webkit-focus-ring-color;
$check-mark-gray: rgb(var(--palette-m3-system-gray-35));
$selected-gray: rgba($app-white, 0.4);
$selected-option-gray: rgba($app-black, 0.12);
$light-gray: rgb(var(--palette-m3-system-gray-95)) !default;
$gray-50: rgb(var(--palette-m3-system-gray-95)) !default;
$label-gray: rgb(var(--palette-m3-system-gray-75)) !default;

$bva-background: rgb(var(--palette-m3-system-gray-10));
$bva-card-background: rgb(var(--palette-m3-system-gray-25));
$bva-text-gray: rgb(var(--palette-m3-system-gray-75));
$bva-incentives: rgb(var(--palette-m3-system-green-80));
$bva-provider-name: #34bffc;

$alert-red: rgb(var(--palette-m3-system-red-40)) !default; // also disclaimer level 1
$alert-secondary: #ffc107 !default; // disclaimer level 2
$alert-highlight: #ffef62 !default;
$bold-highlight: rgba(255, 193, 7, 0.25);
$rating-color-on: $app-rating-on-color !default;
$rating-color-off: $gray-on-white !default;
$info-box-accent-color: #ffbf00 !default;

$global-footer-secondary-background: $gray-on-gray !default;
$compare-tray-title: rgb(var(--palette-m3-system-gray-10)) !default;
$compare-cost: rgb(var(--palette-m3-system-green-40)) !default;
$border-color: rgb(var(--palette-m3-system-gray-85)) !default;
$blue-border: rgb(var(--palette-m3-system-gray-60)) !default;
$medium-gray: rgb(var(--palette-m3-system-gray-35)) !default;

$chat-inbound-bg-color: #0083bd;
$chat-inbound-text-color: $app-white;
$chat-outbound-bg-color: rgb(var(--palette-m3-system-gray-85));
$chat-outbound-text-color: rgb(var(--palette-m3-system-gray-10));

$sos-badge-bg-green: rgb(var(--palette-m3-system-gray-95));
$sos-tooltip-blue: #1b75bc;
$error-color: rgb(var(--palette-m3-system-red-40));
